<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form
          v-if="!editAgent"
          style=""
          @submit.prevent
        >

          <b-row>

            <!--  name -->
            <b-col cols="12">
              <b-form-group
                label="اسم المندوب"
                label-for="v-first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المندوب"
                  rules="required"
                >

                  <b-form-input
                    id="v-first-name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المندوب "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  username -->
             <b-col cols="12">
              <b-form-group
                label="اسم المستخدم"
                label-for="v-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المستخدم"
                  rules="required"
                >

                  <b-form-input
                    id="v-first-name"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المستخدم "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
                
                 <!--  password -->
              <b-col cols="12">
        <b-form-group
                label=" كلمة المرور"
                label-for="v-password"
              >
          <validation-provider
            #default="{ errors }"
            rules="required|password"
            name="password"
            vid="password"
          >
            <b-form-input
              v-model="password"
              type="password"
              :state="errors.length > 0 ? false : null"
              placeholder="كلمة السر"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
              <b-form-group
                label="أماكن الخدمه"
                label-for="v-servicePlaces"
              >
             
                <multiselect
                v-model="places"
                :options="servicePlaces"
                :multiple="true"
                :close-on-select="false"
                trackBy="id"
                label="name"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="اختار أماكن الخدمه"
                :preselect-first="false"
              >
              </multiselect>
               </b-form-group>
      </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                إضافه
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="primary"
                @click="this.reset"
              >
                إعادة تهيئة
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right:15px;"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>

          </b-row>

        </b-form>

        <b-form
          v-else
          style=""
          @submit.prevent
        >

          <b-row>

            <!--  name -->
            <b-col cols="12">
              <b-form-group
                label="اسم المشرف"
                label-for="v-first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المشرف"
                  rules="required"
                >
                  <b-form-input

                    id="v-first-name"
                    v-model="agent.userDisplayName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المشرف"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <!--  username -->
           <b-col cols="12">
              <b-form-group
                label="اسم المستخدم"
                label-for="v-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المستخدم"
                  rules="required"
                >
                  <b-form-input

                    id="v-username"
                    v-model="agent.userName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المشرف"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
               
       <b-col cols="12">
              <b-form-group
                label="أماكن الخدمه"
                label-for="v-servicePlaces"
              >
             
                <multiselect
                v-model="fliteredArray"
                :options="servicePlaces"
                :multiple="true"
                :close-on-select="false"
                trackBy="name"
                label="name"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="اختار اماكن الخدمه"
                :preselect-first="false"
              >
              </multiselect>
               </b-form-group>
      </b-col>
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                تعديل
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="primary"
                @click="this.reset"
              >
                إعادة تهيئة
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right:15px;"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>

          </b-row>

        </b-form>
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormCheckboxGroup, BFormRadio, BDropdown, BDropdownItem, BDropdownDivider, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Multiselect,
  },

  directives: {
    Ripple,
  },
  props: ['id'],
  data() {
    return {

      // ? Default locale for VeeValidate is 'en'
      locale: 'ar',
      // for validation
      required,
      name: null,
      username: null,
      password:null,
      agent:{},
      places:[],
      servicePlaces:[],
      editAgent: false,
      fliteredArray:[]

    }
  },
  created(){
    this.GetPlaces();
     if (this.id) {
      this.editAgent = true
      this.GetAgent(this.$route.params.id);
    }
   
  },
  mounted() {

    // switch to arabic in validation
    localize(this.locale) 
   
  },
  methods: {

    validationForm() {
      if (!this.editAgent) {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            // eslint-disable-next-line
            this.AddAgent();
          }
        })
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            // eslint-disable-next-line
               this.EditAgent();
          }
        })
      }
    },
 async GetPlaces(){
       try {
       const response =  await this.$store.dispatch('GetPlaces');
       if(response.status = 200){
          this.servicePlaces = response.data
          
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
    },
    async GetAgent(agentId){
       try {
       const response =  await this.$store.dispatch('GetAgent',agentId);
       if(response.status = 200){
          this.agent = response.data
          this.fliteredArray =  this.servicePlaces
          .filter(i=>this.agent.servicePlaces.includes(i.name))
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
    },
   async AddAgent() {
      //extract ids from objects 
      this.places = this.places.map(i=>({"ServicePlacesId":i.id}));
      const agent = {
        UserName: this.username,
        UserDisplayName: this.name,
        Password:this.password,
        Role:"User",
        ServicePlaces: this.places


      }
       try {
        const response = await this.$store.dispatch("AddAgent", agent);

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الإضافة بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "agents",
          });
        }
      } catch (err) {
        if (err.response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data,
              icon: "XIcon",
              variant: "warning",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async EditAgent() {
       this.agent.servicePlaces =
        this.fliteredArray.map(i=>({"ServicePlacesId":i.id}));
        const agent = {
          id:this.agent.id,
          userDisplayName: this.agent.userDisplayName,
          userName:this.agent.userName,
          servicePlaces: this.agent.servicePlaces
        }
        try {
        const response = await this.$store.dispatch("UpdateAgent", agent);

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "تم الإضافة بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$router.push({
            name: "agents",
          });
        }
      } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "فشل الاتصال بالسيرفر يرجى المحاولة لاحقا",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
       
      }
    },
    reset() {
      if (this.editAgent) {
        this.agent.userDisplayName = null
        this.agent.userName = null
        this.agent.servicePlaces = null
      } else {
        this.name = null
        this.username = null
        this.password = null
        this.places = [] 
      }
    },

    back() {
      router.push('/agents')
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

